import React, { useState, useEffect } from 'react'
import Hero from '../worksComponents/Hero/Hero'
import Navbar from '../worksComponents/WorksNavbar'
import Sidebar from '../worksComponents/WorksSidebar'
import AV from '../worksComponents/About/AV'
import LiveElectronics from '../worksComponents/About/LiveElectronics'
import DanceTheatre from '../worksComponents/About/DanceTheatre'
import Installations from '../worksComponents/About/Installations'
import Lectures from '../worksComponents/About/Lectures'
import Movies from '../worksComponents/About/Movies'
import MurkMusic from '../worksComponents/About/MurkMusic'
import Residencies from '../worksComponents/About/Residencies'
import Software from '../worksComponents/About/Software'
import SonotypeMusic from '../worksComponents/About/SonotypeMusic'
import Workshops from '../worksComponents/About/Workshops'
import Contact from '../worksComponents/Contact/Contact'
import Footer from '../worksComponents/Footer/Footer'
import { PortfolioProvider } from '../../context/context'

import { heroData, aboutData, contactData, footerData } from '../mock/data'

import {
  projectsAvData,
  projectsDanceData,
  projectsInstallationsData,
  projectsLecturesData,
  projectsLiveData,
  projectsMoviesData,
  projectsMurkData,
  projectsResidenciesData,
  projectsSoftwareData,
  projectsSonotypeData,
  projectsWorkshopsData2019,
  projectsWorkshopsData2018,
} from '../mockDataProjects/data'

function Works() {
  const [hero, setHero] = useState({})
  const [about, setAbout] = useState({})
  const [contact, setContact] = useState({})
  const [footer, setFooter] = useState({})

  const [projectsAv, setProjectsAv] = useState([])
  const [projectsDance, setProjectsDance] = useState([])
  const [projectsInstallations, setProjectsInstallations] = useState([])
  const [projectsLectures, setProjectsLectures] = useState([])
  const [projectsLive, setProjectsLive] = useState([])
  const [projectsMovies, setProjectsMovies] = useState([])
  const [projectsMurk, setProjectsMurk] = useState([])
  const [projectsResidencies, setProjectsResidencies] = useState([])
  const [projectsSoftware, setProjectsSoftware] = useState([])
  const [projectsSonotype, setProjectsSonotype] = useState([])
  const [projectsWorkshops2019, setProjectsWorkshops2019] = useState([])
  const [projectsWorkshops2018, setProjectsWorkshops2018] = useState([])

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setHero({ ...heroData })
    setAbout({ ...aboutData })
    setProjectsAv([...projectsAvData])
    setProjectsDance([...projectsDanceData])
    setProjectsInstallations([...projectsInstallationsData])
    setProjectsLectures([...projectsLecturesData])
    setProjectsLive([...projectsLiveData])
    setProjectsMovies([...projectsMoviesData])
    setProjectsMurk([...projectsMurkData])
    setProjectsResidencies([...projectsResidenciesData])
    setProjectsSoftware([...projectsSoftwareData])
    setProjectsSonotype([...projectsSonotypeData])
    setProjectsWorkshops2019([...projectsWorkshopsData2019])
    setProjectsWorkshops2018([...projectsWorkshopsData2018])
    setContact({ ...contactData })
    setFooter({ ...footerData })
  }, [])
  return (
    <PortfolioProvider
      value={{
        hero,
        about,
        projectsAv,
        projectsDance,
        projectsInstallations,
        projectsLectures,
        projectsLive,
        projectsMovies,
        projectsMurk,
        projectsResidencies,
        projectsSoftware,
        projectsSonotype,
        projectsWorkshops2019,
        projectsWorkshops2018,
        contact,
        footer,
      }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <AV />
      <LiveElectronics />
      <Installations />
      <DanceTheatre />
      <Software />
      <MurkMusic />
      <SonotypeMusic />
      <Movies />
      <Lectures />
      <Workshops />
      <Residencies />
      <Contact />
      {/* <Footer /> */}
    </PortfolioProvider>
  )
}

export default Works
