import React, { useContext, useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
import Tilt from 'react-tilt'
import { Container, Row, Col } from 'react-bootstrap'
import PortfolioContext from '../../../context/context'
import Title from '../Title/Title'
import ProjectImg from '../Image/ProjectImg'

const ProjectsDance = () => {
  const { projectsDance } = useContext(PortfolioContext)

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="" />
          {projectsDance.map((project) => {
            const { title, info, info2, url, repo, image, id } = project

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={500}
                    distance="30px">
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        {title || 'Project Title'}
                      </h3>
                      <div>
                        <p>
                          {info ||
                            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                        </p>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>
                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={url}>
                          Watch
                        </a>
                      )}

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}>
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={500}
                    distance="30px">
                    <div className="project-wrapper__image">
                      <div data-tilt className="thumbnail rounded">
                        <img className="img-project" src={image} alt="" />
                      </div>
                    </div>
                  </Fade>
                </Col>
              </Row>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default ProjectsDance
