import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages'
import WorksPage from './pages/works'
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/works" component={WorksPage} exact />
        {/* <Route path="/works" component={WorksPage} exact /> */}
      </Switch>
    </Router>
  )
}

export default App
