import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.footer`
  background-color: #101522;
  ${'' /* padding-top: 200px; */}
  @media screen and (max-width: 20rem) {
    padding-top: 0px;
  }
  @media screen and (max-width: 37.5rem) {
    padding-top: 50px;
  }
  @media screen and (max-width: 56.25rem) {
    padding-top: 50px;
  }
  @media screen and (max-width: 75rem) {
    padding-top: 100px;
  }
  @media screen and (max-width: 112.5rem) {
    padding-top: 100px;
  }
`

export const FooterWrap = styled.div`
  padding: 48px 24px;
  ${
    '' /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px; */
  }
  margin-left: auto;
`

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
    padding-bottom: 80 px;
  }
`

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 2rem;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
  padding-right: 50px;
`

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;
  padding-left: 50px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-bottom: 80 px;
    padding-left: 0;
  }
`

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  ${'' /* margin-bottom: 16px; */}
  font-weight: bold;
`

export const WebsiteRights = styled.small`
  color: #fff;
  ${'' /* margin-bottom: 16px; */}
  font-size: 1.3rem;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`
export const FooterLinkTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 16px;
  padding: 50px;
  color: #fff;
`

export const TextWrapper = styled.div`
  ${'' /* padding: 50px; */}
  color: #fff;
`
export const Title = styled.div`
  color: #fff;
  font-size: 2.3rem;
  padding-left: 50px;
  margin: 0;
  padding-bottom: 10px;
  font-weight: bold;
`
export const Text = styled.div`
  color: #fff;
  padding-left: 50px;
  padding-bottom: 25px;
  font-size: 2rem;
`

export const Email = styled.a`
  color: #fff;
  text-decoration: none;
`
