import React from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaBandcamp,
  FaVimeo,
} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Title,
  Text,
  TextWrapper,
  Contact,
  Email,
} from './FooterElements'

import { Logo } from '../Navbar/NavbarElements'
import logo from '../../images/logo.png'

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop()
  }
  return (
    <FooterContainer id="footer">
      <FooterWrap>
        <FooterLinkTitle>Contact us</FooterLinkTitle>
        <Title>Get in touch</Title>
        <Text>
          If you run any business in the field of music and multimedia art, get
          in touch with us to collaborate or to find a solution to your project.
        </Text>

        <Title>Email</Title>
        <Text>
          <Email href="mailto:info@sonotype.net">info@sonotype.net</Email>
        </Text>
        <Title>Phone</Title>
        <Text>+49 157 88 50 50 99</Text>

        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <Logo to="/" src={logo} alt="sono.type" />
            </SocialLogo>
            <WebsiteRights>sono.type © 2020 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/murk.hrd/"
                target="_blank"
                aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/sono.type/"
                target="_blank"
                aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UC1VgX7YVk2ldMSuyhnNgTBw/featured?view_as=subscriber"
                target="_blank"
                aria-label="Youtube"
                rel="noopener noreferrer">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="https://vimeo.com/avsonotype"
                target="_blank"
                aria-label="Vimeo"
                rel="noopener noreferrer">
                <FaVimeo />
              </SocialIconLink>
              <SocialIconLink
                target="_blank"
                aria-label="Bandcamp"
                href="https://sonotype.bandcamp.com/releases">
                <FaBandcamp />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.linkedin.com/in/marcoaccardi/"
                target="_blank"
                aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
