import React from 'react'
import Works from '../components/Works'
import ScrollToTop from '../components/ScrollToTop'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../style/main.scss'
const WorksPage = () => {
  return (
    <>
      <ScrollToTop />
      <Works></Works>
    </>
  )
}

export default WorksPage
