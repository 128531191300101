import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from './WorksSidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {/* <SidebarMenu> */}
        <SidebarLink
          to="av"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Av
        </SidebarLink>
        <SidebarLink
          to="live"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Live-electronics
        </SidebarLink>
        <SidebarLink
          to="installations"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}>
          Installations
        </SidebarLink>
        <SidebarLink
          to="dancetheatre"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Dance-Theatre
        </SidebarLink>
        <SidebarLink
          to="software"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Software
        </SidebarLink>
        <SidebarLink
          to="murk"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Murk
        </SidebarLink>
        <SidebarLink
          to="music"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Music
        </SidebarLink>
        <SidebarLink
          to="movies"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Movies
        </SidebarLink>
        <SidebarLink
          to="lectures"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Lectures
        </SidebarLink>
        <SidebarLink
          to="workshops"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Workshops
        </SidebarLink>
        <SidebarLink
          to="residencies"
          onClick={toggle}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-60}>
          Residencies
        </SidebarLink>
        {/* </SidebarMenu> */}
        {/* <SideBtnWrap>
          <SidebarRoute to="/skills">
            <FontAwesomeIcon icon={faCode} />
          </SidebarRoute>
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
