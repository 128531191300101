// import React from 'react'
// import Tutoring from '../../images/tutoring.jpg'
// import SoftwareDevelopment from '../../images/software-development.jpg'
// // import VisualArt from '../../images/visual-art.jpg'
// import MusicSound from '../../images/music-sound-design.jpg'

// import {
//   ServicesContainer,
//   ServicesH1,
//   ServicesWrapper,
//   CardLink,
//   ServicesCard,
//   ServicesIcon,
//   ServicesH2,
//   ServicesP,
// } from './ServicesElements'

// const Services = () => {
//   return (
//     <ServicesContainer id="services">
//       <ServicesH1>Our Services</ServicesH1>
//       <ServicesWrapper>
//         <CardLink to="/skills">
//           <ServicesCard>
//             <ServicesIcon src={Tutoring} />
//             <ServicesH2>Max/MSP/Jitter lessons</ServicesH2>
//             {/* <ServicesP>
//             Train beginner-intermediate level artists to develop their audio and
//             visual projects.
//           </ServicesP> */}
//           </ServicesCard>
//         </CardLink>

//         <CardLink to="/skills">
//           <ServicesCard>
//             <ServicesIcon src={SoftwareDevelopment} />
//             <ServicesH2>Software Development</ServicesH2>
//             {/* <ServicesP>
//             Create custom software for your audio, visual or web project.
//           </ServicesP> */}
//           </ServicesCard>
//         </CardLink>

//         <CardLink to="/skills">
//           <ServicesCard>
//             <ServicesIcon src={MusicSound} />
//             <ServicesH2>Sound design and music</ServicesH2>
//             {/* <ServicesP>We make sonification for your products.</ServicesP> */}
//           </ServicesCard>
//         </CardLink>

//         <CardLink to="/skills">
//           <ServicesCard>
//             <ServicesIcon src={MusicSound} />
//             <ServicesH2>Workshops</ServicesH2>
//             <ServicesP> </ServicesP>
//           </ServicesCard>
//         </CardLink>
//       </ServicesWrapper>
//     </ServicesContainer>
//   )
// }

// export default Services

import React from 'react'
import Tutoring from '../../images/tutoring.jpg'
import SoftwareDevelopment from '../../images/software-development.jpg'
// import VisualArt from '../../images/visual-art.jpg'
import MusicSound from '../../images/music-sound-design.jpg'

const Services = () => {
  return (
    <>
      <section id="services" className="projects-section bg-dark">
        <div className="container">
          <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
            <div className="col-lg-6">
              <img className="img-fluid" src={Tutoring} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-left text-lg-left">
                    <h2 className="text-white">Max/MSP/Jitter lessons</h2>
                    <p className="mb-0 text-white-50">
                      Train beginner-intermediate level artists to develop their
                      audio and visual projects.
                    </p>
                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center no-gutters">
            <div className="col-lg-6">
              <img className="img-fluid" src={SoftwareDevelopment} alt="" />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-left h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-left text-lg-left">
                    <h2 className="text-white">Software Development</h2>
                    <p className="mb-0 text-white-50">
                      Create custom software for your audio, visual or web
                      project.
                    </p>
                    <hr className="d-none d-lg-block mb-0 mr-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
            <div className="col-lg-6">
              <img className="img-fluid" src={MusicSound} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-left text-lg-left">
                    <h2 className="text-white">Sound design and music</h2>
                    <p className="mb-0 text-white-50">
                      We make sonification for your products.
                    </p>
                    <hr className="d-none d-lg-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services
