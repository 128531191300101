import imgAbout from '../../images/about.jpg'
import imgWorks from '../../images/works.jpg'
import imgServices from '../../images/services.jpg'

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: 'About us',
  headline: '10 years of experience in the field',
  description:
    'live and work in Berlin in the field of electro-acoustic music, dance-theatre, audio-visual art, installations and EDM music production and software development',
  buttonLabel: 'more',
  imgStart: false,
  img: imgAbout,
  alt: 'about',
  dark: false,
  primary: true,
  darkText: false,
}

export const homeObjTwo = {
  id: 'works',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'works',
  headline: `Music Production. Installations. Software. Workshops. Teaching. AudioVisual.`,
  description: '',
  buttonLabel: 'more',
  imgStart: true,
  img: imgServices,
  alt: 'Piggybank',
  dark: true,
  primary: false,
  darkText: true,
}

// export const homeObjThree = {
//   id: 'contact',
//   lightBg: true,
//   lightText: false,
//   lightTextDesc: false,
//   topLine: 'Join our Team',
//   headline: 'Creating an account is extremely easy',
//   description:
//     "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
//   buttonLabel: 'Start Now',
//   imgStart: false,
//   img: imgWorks,
//   alt: 'Papers',
//   dark: true,
//   primary: false,
//   darkText: true,
// }
