import React, { useContext, useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-bootstrap'
import Title from '../Title/Title'
import PortfolioContext from '../../../context/context'
import ProjectsDance from '../Projects/ProjectsDance'
import { projectsDanceData } from '../../mockDataProjects/data'

const DanceTheatre = () => {
  const [projectsDance, setProjectsDance] = useState([])

  const { about } = useContext(PortfolioContext)

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }

    setProjectsDance([...projectsDanceData])
  }, [])

  return (
    <section className="head-section" id="dancetheatre">
      <section id="head">
        <Container>
          <Title title="Dance-Theatre" />
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="about-wrapper__image"></div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <ProjectsDance />
    </section>
  )
}

export default DanceTheatre
