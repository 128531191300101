import React, { useRef, useContext, useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'
import PortfolioContext from '../../../context/context'
// import Video from '../../../videos/video-work.mov'
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
}
const videoWork = `${process.env.PUBLIC_URL}/videos/video_work.webm`
const imageWork = `${process.env.PUBLIC_URL}/videos/video_work.jpg`

const Header = () => {
  const { hero } = useContext(PortfolioContext)
  const { title, name, subtitle, cta } = hero

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const videoParentRef = useRef()
  const [shouldUseImage, setShouldUseImage] = useState(false)
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0]

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '') // leave no stones unturned :)
        player.autoplay = true

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play()
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none'
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [])

  return (
    <section id="hero">
      <Container>
        {shouldUseImage ? (
          <div className="video-container">
            <img
              className="hero-background"
              src={imageWork}
              alt="Muted Video"
            />
          </div>
        ) : (
          <div
            className="video-container"
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
        <video
        className="hero-background"
          loop
          muted
          autoplay
          playsinline
          preload="none"
        >
        <source crossOrigin="anonymous" src=${videoWork} type="video/webm" />
        </video>`,
            }}
          />
        )}
        {/* <div className="video-container">
          <video
            className="hero-background"
            playsInline
            autoPlay
            // loop
            muted
            src={videoWork}
            type="video/webm"></video>
        </div> */}
        <Fade
          left={isDesktop}
          bottom={isMobile}
          duration={500}
          delay={500}
          distance="30px">
          <h1 className="hero-title">
            {title || 'Explore the '}{' '}
            <span className="text-color-main">{name || 'Sonotype'}</span>
            <br />
            {subtitle || 'world'}
          </h1>
        </Fade>
        <Fade
          left={isDesktop}
          bottom={isMobile}
          duration={500}
          delay={1000}
          distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="av" smooth duration={500} offset={-60}>
                {cta || 'more '}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  )
}

export default Header
