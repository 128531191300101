import React, { useRef, useState, useEffect } from 'react'
import { Button } from '../ButtonElements'

// import Video from '../../videos/video.mov'
// import {
//   HeroContainer,
//   HeroBg,
//   VideoBg,
//   HeroContent,
//   HeroH1,
//   HeroP,
//   HeroBtnWrapper,
//   ArrowForward,
//   ArrowRight,
// } from './HeroElements'
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
}
const videoMain = `${process.env.PUBLIC_URL}/videos/video.webm`

const image = `${process.env.PUBLIC_URL}/videos/video.jpg`

function HeroSection() {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }
  const videoParentRef = useRef()
  const [shouldUseImage, setShouldUseImage] = useState(false)
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0]

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '') // leave no stones unturned :)
        player.autoplay = true

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play()
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = 'none'
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [])
  return (
    <div className="hero-container" id="home">
      {shouldUseImage ? (
        <div className="hero-bg">
          <img className="video-bg" src={image} alt="Muted Video" />
        </div>
      ) : (
        <div
          className="hero-bg"
          ref={videoParentRef}
          dangerouslySetInnerHTML={{
            __html: `
        <video
          className="video-bg"
          loop
          muted
          autoplay
          playsinline
          preload="none"
        >
        <source crossOrigin="anonymous" src="${videoMain}" type="video/webm" />
        </video>`,
          }}
        />
      )}
      ;
      {/* <div className="hero-bg">
        <video
          className="video-bg"
          playsInline
          autoPlay
          // loop
          muted
          src={video}
          type="video/webm"
        />
      </div> */}
      <div className="hero-content">
        <h1 className="hero-h1">Sono.type</h1>
        <p className="hero-p">
          Software developer, multimedia artist, teacher and music producer.
        </p>
        {/* <div className="hero-btn-wrapper">
          <Button
            to="signup"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}>
            learn more {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </div> */}
      </div>
    </div>
  )
}

export default HeroSection
