import { nanoid } from 'nanoid'

const image = `${process.env.PUBLIC_URL}/images`

// PROJECTS AV DATA
export const projectsAvData = [
  {
    id: nanoid(),
    image: `${image}/AV/war_of_worlds.png`,
    title: 'The war of worlds /w VJkET, 2020',
    info:
      'We tend to think that our lives are driven by some kind of fate, and indeed they are, but not exactly what we have thought. There are a nature high-end design and a master plan, something we vaguely can see or understand, elaborated by a higher form of intelligence. We can’t say for sure if it’s the summum of a technologically advanced civilization, or an ancient and ethereal entity, like a God/ess. It gave us the concepts of time and space, and along with that the arts related to them, such as music and visual art. Through them, we pay tribute and decipher its mystery.',
    info2: '',
    url:
      'https://www.youtube.com/watch?v=xAP4qvarQqQ&fbclid=IwAR1n40K0UO205lCy4PxydxE0RHmNQvXSUbNvMzG1UjDJMLgm95_u57S4F9o',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/AV/moirai.png`,
    title: 'Moir.AI /w Geso, 2018',
    info:
      'We tend to think that our lives are driven by some kind of fate, and indeed they are, but not exactly what we have thought. There are a nature high-end design and a master plan, something we vaguely can see or understand, elaborated by a higher form of intelligence. We can’t say for sure if it’s the summum of a technologically advanced civilization, or an ancient and ethereal entity, like a God/ess. It gave us the concepts of time and space, and along with that the arts related to them, such as music and visual art. Through them, we pay tribute and decipher its mystery.',
    info2: '',
    url: 'https://vimeo.com/277902122',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/AV/cypher.png`,
    title: 'NEON cipher [PATH OF THE SAMURAI] /w VJkET, 2018',
    info: `Buddha Y-NǾ says no lies. He lives in cipherspace. He studies Matrix acid stimulation. It speaks when it comes: moans, cries it's digital eyes out and says: ◦◌○. It means it doesn't matter anymore. Cipher-neon abstract state machines he loves. And abstract neon-state cipher-machines reciprocate Y-nǾ-thing's feeling. VǾid is undetectable by sensing devices. Cause it's subset of any set, including set that coincides with void-set. He's ultra and violet. He breathes with all the beautiful gases of Sprawl: xenon, argon and especially neon. Neon, says Buddha Y-NǾ, is n.aeon, or new aeon. Of neo-n, where "n" is a first letter of the word "ninja". Buddha Y-NǾ teaches disco-neck-tion.`,
    info2: '',
    url: 'https://www.youtube.com/watch?v=qnQ41g1a2qs',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/AV/idobj5.png`,
    title: 'id:obj-5 /w Giovanni Magaglio, 2016',
    info:
      'is an audio/visual piece based on the aesthetic of failure. We live in the post-digital era and our control on the technology is an illusion. The musical process consists in the musicalisation of noise (accepting the noise as a valid sound source) and the “noisification” of music (not conventional use of conventional instruments to create distorted sounds). The rhythmic organisation of the sounds highlights the aesthetic of noise, making pleasant the hardness of the timbres. Deep and dark textures are disturbed by ‘glitches’ and errors, which influence the form and structure alongside black and white geometrical shapes. The work is based on the exploration and the experimentation of the possibilities of waveshaping synthesis and how to relate the results in a musical way.',
    info2: '',
    url: 'https://cycling74.com/projects/idobj-5',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS LiveElectronics DATA
export const projectsLiveData = [
  {
    id: nanoid(),
    image: `${image}/Live/2018_double_bind.jpg`,
    title: 'Unsuk Chin - double bind?, 2018',
    info:
      'He has curated the live electronics for a electro-acoustic piece for violin, electronics and 6 loudspeakers composed at IRCAM.',
    info2: 'International biennial of contemporary music - Koper, Slovenia.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Live/2018_brush.jpg`,
    title: 'MiniM Ensemble - Brush, 2018',
    info:
      'Brush is a composition for a musician and a computer, which creates a sonic bends texture, different in every performance. You can choose between 4 different versions, the musical range to play, the total duration of the piece and the amount of dissonance between the notes. These parameters influence the way in which the score will be generated in terms of macro and micro structure.',
    info2: 'Hochschule für Musik und Theater - Munich, Germany',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Live/2018_auf_den_sieben_tagen.jpg`,
    title: 'Karlheinz Stockhausen - Auf den sieben Tagen, 2018',
    info:
      'It is a collection of 15 text compositions composed by Stockhausen in 1968 and characterised as intuitive music. The piece has been performed alongside Georg-Emmanuel Schneider and  Michele Marelli.',
    info2: 'International biennial of contemporary music" - Koper, Slovenia.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Live/2016_acousmatic_concert.jpg`,
    title: 'Acousmatic concerts, 2016',
    info:
      'He has curated the installation of an acusmonium of 33 loudspeakers and spazialised acoumatic pieces: François Bayle - 7 preludi, Manfredi Clemente - La boucle, Bernard Parmegiani - La creation du monde. ',
    info2: 'Chiesa di Santa Chiara - Cagliari, Italy.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Live/2015_solo.jpg`,
    title: 'Karlheinz Stockhausen - Solo, 2015',
    info:
      'It is an electro-acoustic piece for a solo instrument and tape. This piece has been performed at the "Festival di musica contemporanea di Cagliari", ""Teatro Jolly" and "Conservatorio A. Scarlatti di Palermo".',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Live/2015_altra_voce.jpg`,
    title: 'Luciano Berio - Altra Voce, 2015',
    info:
      'It is a piece for alto-flute, mezzo-soprano and live electronics composed in 1999. It has been performed at the "Conservatorio A. Scarlatti di Palermo" alongside Giovanni Magaglio, Chiemi Wada and Luigi Sollima.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS Installations DATA
export const projectsInstallationsData = [
  // {
  //   id: nanoid(),
  //   image: `${image}/Installations/2020_moss.png`,
  //   title: 'MOSS /w Marco Barotti, 2020-2021',
  //   info:
  //     'A kinetic sound sculpture that reveals the impact of air pollution on our environment and living organism. Driven by real-time data generated by the IQAir service, the sculpture constantly reacts chaning its shape and soundscape',
  //   info2: 'Berlin, Germany',
  //   url: 'https://player.vimeo.com/video/437405995',
  //   repo: 'https://www.marcobarotti.com/moss', // if no repo, the button will not show up
  // },
  {
    id: nanoid(),
    image: `${image}/Installations/2019_createure_comforts.png`,
    title:
      'Creature Comforts - How to organically share your mind /w Error-43, 2019',
    info:
      'Project realized with the collective Error-43 (Miguel Romaō Martinho, Frederico Pimpaō) and Ryan Cherewaty. It is a multi-user VR experience where users interact together with the common goal of creating an artificial lifeform inside the virtual space. VR technology is commonly used as a solitary experience, replacing the physicality of the human experience with a virtual world that dislocates the user from beings around them. Through the performance of specific physical movements achieved in tandem with the other user, a platform for mutual virtual creation can be realised. This breaks down the barriers of the solitary VR experience and creates a conceptual link between minds in the virtual world, an encounter unique to this technological model.',
    info2: 'Lisbon, Portugal',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Installations/2019_kuma.jpg`,
    title: 'Kūµa, 2019',
    info: 'Open Exhibition - Temp Studio - Lisbon, Portugal',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Installations/2019_es_3.0.jpg`,
    title: 'Es 3.0 /w Nuno Mika, 2019',
    info: 'Open Exhibition - Temp Studio - Lisbon, Portugal',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS DanceTheatre DATA
export const projectsDanceData = [
  {
    id: nanoid(),
    image: `${image}/DanceTheatre/2017_typolodigitation.jpg`,
    title: 'Typologitation /w Gau Company, 2017',
    info:
      'The project combines electronic music, visual arts and contemporary dance by the choreographer and dancer Nadine Freisleben. We research on the development of typographical information towards digital information and which impact it has on the human psyche. Typography is the written element of communication whose appearance and texture has changed in the last decades. Initially, written information was created on natural materials. So it was not only visible but touchable. The fact, that a human body can use two of its senses, has a high impact on the brain. We combine physical movement with virtual visuals and digital music to highlight the psychological impact of virtual communication on the human body and mind. The aim is to experience the small line between reality and fiction.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/DanceTheatre/2017_exit_to_endorphia_01.png`,
    title: 'Gau Company, Exit to Endorphia vol.2 - lost & found, 2017',
    info:
      'In the human psychological understanding of western social behaviour, happiness is generally the mental or an emotional state which is the highest goal to reach according to each individual mindset about well-being. The piece succeeds to question aesthetically and substantially the definition of being happy. The voice of the language through dance, acrobatics and live music narrates the performers, as well as the spectators, through a journey of undergone and foreseen true-to-life experiences.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/DanceTheatre/2016_cariddi_01.jpg`,
    title: 'Stellario Di Blasi, Cariddi - mari nun ci n’è cchiù, 2016',
    info:
      'First project of a trilogy on the reading of the myths and natural elements of the Strait of Messina. The work is conceived as a visionary provocation, as a presage slowly overwhelmed by the outcome of unhealthy human actions in a hypothetical future relationship with the contradiction of progress. The universe of Cariddi is understood as an imaginative evocation and the gestural memory of everything that the sea can preserve within it: from the beings who inhabit it to its natural, incontinable and poetic being, with its fragrances, its history. It deals with a landscape of fragmented soul and lost values, space and time swallowed in a sick, drained, crumbling and fervid bitterness. This fragile protest for a lush tomorrow emerges into nothingness: it is in the construction of the absence, in this chromatic and narrative absence, that the performance hollows into balance between reality and utopia, memory and overwhelm.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/DanceTheatre/2016_zopf.jpg`,
    title: 'Enrico Musmeci, Zopf - Suite Électronique, 2016',
    info: `Weaves are an ornamental form based on the repetition of motifs composed of intertwined curves, more or less complex. Equally complex are the deep processes of métissage, which generate beauty, cultural comparison and metamorphosis of collective identity. The suite électronique was born from a contemporary reading of some Bach's dances, in order to compare two compositional approaches to create a marriage of bodies and electronic sounds. This multidisciplinary creation emphasizes the encounter/clash of two apparently divergent languages - baroque and contemporary - in order to create unusual soundscapes, through new rhythms, colors and expressive forms. 
    The interweaving - "Zopf" - is a constant source of inspiration to develop musical themes in choreographic paintings with their own dramaturgy.`,
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/DanceTheatre/2015_diva_04.png`,
    title: 'Enrico Musmeci, Diva - questa sono io, 2015',
    info: `The path of some individuals, through their own image, in the 20th century began the phenomenon of divism.
    Among the most famous icons of the film world in the first half of the twentieth century, Marlene Dietrich was a true myth. One of the first divas, thanks to a set of qualities, a model of femme fatale par excellence.
    Marilyn Monroe was a symbol out of all time, for her charm and sensuality. According to Dietrich, the first true sex symbol. A real icon of pop culture, she became a victim of the star system!
    Brigitte Bardot, an actress considered a sexy icon, "the European Marilyn", was the idol of young people! Since her cinematic abandonment she has become a well-known animal rights activist.`,
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS Software DATA
export const projectsSoftwareData = [
  {
    id: nanoid(),
    image: `${image}/Software/2019_glytch.type.jpg`,
    title: 'Glitch.type /w Xenorama , 2019',
    info:
      'A Max4Live device to help the sound designers in the creative process of glitch sound composition.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Software/2019-2021_strati.png`,
    title: 'Strati /w Manfredi Clemente, 2019',
    info:
      'A software that aims at enhancing the spatial gestures and articulation in live diffusion on acousmonium whilst reducing the physical gesture of the performer and the number of physical controllers in use.',
    info2: '',
    url: 'http://www.manfrediclemente.it/en/2019/04/02/strati/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Software/2018_brush.png`,
    title: 'Brush /w MiniM Ensemble, 2018',
    info:
      'Is a generative music stand-alone application, developed by the MiniM Ensemble. The piece is for a solo instrumentalist and computer.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Software/2015_altra_voce.png`,
    title: 'Altra Voce /w MiniM Ensemble, 2015',
    info:
      'A Max patch developed by the MiniM Ensemble in order to do the live electronics of piece composed by Luciano Berio in 1999 for flute and soprano.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Software/2015_solo.png`,
    title: 'Solo /w MiniM Ensemble, 2015',
    info:
      'A Max patch developed by the MiniM Ensemble in order to do the live electronics of the piece for magnetic tape composed by K.Stockhausen in 1965.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS Murk Dubstep DATA
export const projectsMurkData = [
  {
    id: nanoid(),
    image: `${image}/Murk/2020_enc_murk_shiva.jpg`,
    title: 'Murk & Shiva - ENC052D, 2020',
    info: 'Encrypted Audio - London, UK',
    info2: 'digital',
    url: 'https://soundcloud.com/encryptedaudio/enc052d-murk-shiva-pre-order',
    repo: 'https://buy.encryptedaudio.com/album/enc052d-murk-shiva', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2020_enc.jpg`,
    title: 'Murk & Shiva - ENC046D, 2020',
    info: 'Encrypted Audio - London, UK',
    info2: 'digital',
    url: 'https://soundcloud.com/encryptedaudio/enc046d-murk-shiva-pre-order',
    repo: 'https://buy.encryptedaudio.com/album/enc046d-murk-shiva', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2020_murk_vgb.jpg`,
    title: 'Murk & VGB EP, 2020',
    info: 'bandcamp',
    info2: '',
    url: 'https://soundcloud.com/vgb/murk-vgb-ryso-nous-out-now',
    repo: 'https://vgbdubstep.bandcamp.com/album/murk-vgb-ryso-nous', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2018_koma_rmx.jpg`,
    title: 'Koma - Uncle Sullivan (Murk Remix), 2018',
    info: 'Encrypted Audio - London, UK',
    info2: 'vinyl, digital',
    url: 'https://soundcloud.com/murk_official/koma-uncle-sullivan-murk-rmx',
    repo:
      'https://buy.encryptedaudio.com/track/env018-koma-uncle-sullivan-murk-remix', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2018_akousma.jpg`,
    title: 'Murk & Shiva - Akousma ft. Rob, 2018',
    info: 'CueLine Records - Copenhagen, DK',
    info2: 'vinyl, digital',
    url:
      'https://soundcloud.com/magnetic-magazine/murk-shiva-akousma-ft-rob-clv004strictly-140-premiere',
    repo:
      'https://www.redeyerecords.co.uk/vinyl/97567-clv004-elements-part-1-va', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2015_hut.jpg`,
    title: 'Murk & Shiva - Hut, 2015',
    info: 'Version Collective - Copenhagen, DK',
    info2: 'digital',
    url:
      'https://soundcloud.com/murk_official/murk-shiva-hut-certified-organic-2-vcc002-part-1-december-27th-2016',
    repo:
      'https://versioncollective.bandcamp.com/album/certified-organic-2-part-1-vcc002', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2015_kevlar.jpg`,
    title: 'Murk & Shiva - Kevlar, 2015',
    info: 'Subaltern/Tuba - London, DK / USA',
    info2: 'digital',
    url:
      'https://soundcloud.com/subaltern-records/04-murk-shiva-kevlar-out-may',
    repo:
      'https://www.junodownload.com/products/transatlantic-vol-2/2774846-02/', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2015_warm_space.jpg`,
    title: 'Shiva - Warm Space (Murk Remix), 2015',
    info: 'CueLine Records - Copenhagen, DK',
    info2: 'digital',
    url: 'https://soundcloud.com/shiva15606/warm-space-murk-remix',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2015_consciousness.png`,
    title: 'Murk - Consciousness EP, 2015',
    info: 'Instigate Recordings - Bristol, UK',
    info2: 'digital',
    url:
      'https://soundcloud.com/murk_official/insti006-murk-consciousness-ep-forthcoming-280915',
    repo:
      'https://www.junodownload.com/products/shiva-warm-space-ep/3206386-02/', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2015_wearechildren.jpg`,
    title: 'WeAreChildren - Gaved (Mentha & Murk Remix), 2015',
    info: 'WeAreChildren - London, UK',
    info2: 'vinyl, digital',
    url:
      'https://soundcloud.com/wacwmsmusic/gaved-mentha-murk-remix-2?in=wacwmsmusic/sets/june-12-we-are-children-we',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Murk/2013_1992.jpg`,
    title: 'Mentha & Murk - 1992, 2013',
    info: 'Subaltern Records - London, UK',
    info2: 'vinyl, digital',
    url: 'https://soundcloud.com/subaltern-records/sets/subalt001',
    repo: 'https://subalternrecords.com/store/vinyl/subalt001/', // if no repo, the button will not show up
  },
]

// PROJECTS SONOTYPE MUSIC DATA
export const projectsSonotypeData = [
  {
    id: nanoid(),
    image: `${image}/Sonotype_music/Artwork_001.jpg`,
    title: 'Sono.type - id_obj_n, 2020',
    info: 'bandcamp',
    info2: 'digital',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS MOVIE DATA
export const projectsMoviesData = [
  {
    id: nanoid(),
    image: `${image}/Movies/genesys.png`,
    title: 'Life Evolution Ecology: Genesys, 2020',
    info:
      'It’s a short documentary entirely filmed in Sicily. The main character is the landscape of the island, shaped by the peculiar geological dynamics over the ages.',
    info2:
      'Awards: Milano Montagna Video Awards, Roma creative contest, Human/Nature international film festival, Ferrara film corto festival',
    url: 'https://vimeo.com/345704969',
    repo: 'https://www.lifebeyondthecamera.com/', // if no repo, the button will not show up
  },
]

// PROJECTS Lectures DATA
export const projectsLecturesData = [
  {
    id: nanoid(),
    image: `${image}/Lectures/2015_2018_brush_01.jpg`,
    title: 'MiniM Ensemble - Brush, 2015-2018',
    info:
      'About the practical and theoretical issues of programming and performing.',
    info2: `//Conservatorio di musica, Palermo (IT),
    //Conservatorio di musica, Cagliari (IT),
    //Ableton HQ, Berlin (DE),
    //Spektrum, Berlin (DE)`,
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Lectures/2015_altra_voce.png`,
    title: 'L. Berio - Altra Voce, 2015',
    info: 'About the issues of programming and performing.',
    info2: '//Conservatorio di musica, Cagliari (IT)',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Lectures/2015_solo.png`,
    title: 'K. Stockhausen - Solo, 2015',
    info: 'About the issues of programming and performing.',
    info2: `//Conservatorio di musica, Cagliari (IT)
    //University of Palermo, Palermo (IT)`,
    url: '',
    repo: '', // if no repo, the button will not show up
  },
]

// PROJECTS WORKSHOPS DATA
export const projectsWorkshopsData2019 = [
  {
    id: nanoid(),
    date: '26-27 April',
    title: 'Audio-Visual Design with Max/MSP/Jitter',
    place: 'The Workshop, Berlin (GER)',
  },
  {
    id: nanoid(),
    date: '17 April',
    title: 'Sound design with MaxLive',
    place: 'Griesmuhle, Berlin (GER)',
  },
  {
    id: nanoid(),
    date: '6-7 April',
    title: 'Audio-visual design with Max/MSP/Jitter',
    place: 'Stour Space, London (UK)',
  },
  {
    id: nanoid(),
    date: '11- 14 March',
    title: 'Audio-Visual Design with Max/MSP/Jitter',
    place: 'Magneti Cowork, Palermo (IT)',
  },
  {
    id: nanoid(),
    date: '15-17 February',
    title: 'Audio-Visual Design with Max/MSP/Jitter',
    place: 'The Workshop, Berlin (GER)',
  },
  {
    id: nanoid(),
    date: '8-10 February',
    title: 'Sound design with Max4Live',
    place: 'The Mill, Lisbon (PT)',
  },
  {
    id: nanoid(),
    date: '19 January',
    title: 'FM Synthesis with Max4Live',
    place: 'Sveta Baar, Tallinn (EE)',
  },
]
export const projectsWorkshopsData2018 = [
  {
    id: nanoid(),
    date: '24 November',
    title: 'FM Synthesis with Max4Live',
    place: 'hdpk, Berlin (GER)',
  },
  {
    id: nanoid(),
    date: '31 July',
    title: 'Introduction to Max/MSP',
    place: 'Green House, Berlin (GER)',
  },
]

// PROJECTS RESIDENCIES DATA
export const projectsResidenciesData = [
  {
    id: nanoid(),
    image: `${image}/Residencies/2018_temp_studio.jpg`,
    title: 'Temp Studio, 2018',
    info:
      'Worked with Nuno Mika on a holographic installation and an art installation based on a woofer, iron plate, water and oil. Todos, Lisbon (PT)',
    info2: '',
    url: 'https://vimeo.com/317004577',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    image: `${image}/Residencies/2017_temp_studio.jpg`,
    title: 'Temp Studio, 2017',
    info:
      'Worked with Nadine Freisleben on "Typologitation" a dance-theatre and audio-visual project Anjos70 - Lisbon (PT)',
    info2: '',
    url: 'https://vimeo.com/253353615',
    repo: '', // if no repo, the button will not show up
  },
]
