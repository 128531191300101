import React, { useContext, useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-bootstrap'
import Title from '../Title/Title'
import AboutImg from '../Image/AboutImg'
import PortfolioContext from '../../../context/context'
import ProjectsInstallations from '../Projects/ProjectsInstallations'
import { projectsInstallationsData } from '../../mockDataProjects/data'

const Installations = () => {
  const [projectsInstallations, setProjectsInstallations] = useState([])

  const { about } = useContext(PortfolioContext)
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }

    setProjectsInstallations([...projectsInstallationsData])
  }, [])

  return (
    <section className="head-section" id="installations">
      <section id="head">
        <Container>
          <Title title="Installations" />
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="about-wrapper__image">
                  {/* <AboutImg alt="profile picture" filename={img} /> */}
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <ProjectsInstallations />
    </section>
  )
}

export default Installations
