import React, { useContext, useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
import Tilt from 'react-tilt'
import { Container, Row, Col } from 'react-bootstrap'
import PortfolioContext from '../../../context/context'
import Title from '../Title/Title'

const ProjectsWorkshops = () => {
  const { projectsWorkshops2019 } = useContext(PortfolioContext)
  const { projectsWorkshops2018 } = useContext(PortfolioContext)

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="2019" />
          {projectsWorkshops2019.map((project) => {
            const { date, title, place, id } = project

            return (
              <Row key={id}>
                <Col lg={8} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={500}
                    distance="30px">
                    {/* <div className="project-wrapper__text"> */}
                    <p>{date}</p>
                    <h6 className="project-wrapper__text-title">{title}</h6>
                    <p className="mb-4">{place}</p>

                    <hr />
                    {/* </div> */}
                  </Fade>
                </Col>
              </Row>
            )
          })}
          <Title title="2018" />
          {projectsWorkshops2018.map((project) => {
            const { date, title, place, id } = project

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={500}
                    distance="30px">
                    <div className="project-wrapper__text">
                      <p>{date}</p>
                      <h6 className="project-wrapper__text-title">{title}</h6>
                      <div>
                        <p>{date}</p>
                        <p className="mb-4">{place}</p>
                      </div>
                      <hr />
                    </div>
                  </Fade>
                </Col>
              </Row>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default ProjectsWorkshops
