import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import { IconContext } from 'react-icons/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'

import {
  Logo,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './WorksNavbarElements'

import logo from '../../../images/logo.png'

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavLogo to="/">
            <Logo to="/" onClick={toggleHome} src={logo} alt="sono.type" />
          </NavLogo>
          <NavbarContainer>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="av"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Av
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="live"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Live
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="installations"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Installations
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="dancetheatre"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  DanceTheatre
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="software"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Software
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="murk"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Murk
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="music"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Music
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="movies"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Movies
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="lectures"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Lectures
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="workshops"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Workshops
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="residencies"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}>
                  Residencies
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
